<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
          <el-input v-model="queryParams.KeyWord" placeholder="请输入关键字">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        <!-- <span>专业：</span>
        <el-select class="mr-20" v-model="queryParams.MajorId" filterable clearable>
          <el-option v-for="item in majorList" :key="item.Id" :label="item.MajorBase" :value="item.Id"></el-option>
        </el-select>
        <span>状态：</span>
        <el-select class="mr-20" v-model="queryParams.Status" clearable>
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">搜索</el-button> -->
      </div>
      <div class="right">
        <el-button type="primary" @click="handleCreate">新增</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" v-loading="loading" border height="640px">
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <el-tag v-if="item.prop === 'Status_V'" class="u-radius-16" :type="scope.row['Status'] | statusFilter">
              {{ scope.row[item.prop] }}</el-tag>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="300">
        <template slot-scope="scoped">
          <el-button type="text" icon="el-icon-view" class="btn-color" @click="handleView(scoped.row.Id)">查看</el-button>
          <el-button type="text" icon="el-icon-edit" class="btn-color" @click="handleEdit(scoped.row.Id)">编辑</el-button>
          <!-- <el-button type="text" icon="el-icon-document-checked" class="btn-color" @click="handleSubmit(scoped.row.Id)" v-if="scoped.row.Status === 0 && scoped.row.Status !== 4">提交</el-button> -->
          <el-button type="text" icon="el-icon-delete" class="hover-danger" @click="handleDelete(scoped.row.Id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPageList, deleteLayout, getMajorList, submit } from "@/api/internshipLayout";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    SchoolId() {
      if (this.RoleType === StaffTypeEnum.School) {
        let temp = this.employee.find(el => el.StaffType === this.RoleType);
        return temp.Employer.Id;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      loading: false,
      queryParams: {
        KeyWord:'',
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "名称",
          prop: "Name"
        },
        {
          label: "合作企业",
          prop: "EnterpriseName"
        },
        {
          label: "课程数量",
          prop: "CourseCount"
        },
        {
          label: "老师人数",
          prop: "TeacherCount"
        },
        {
          label: "学生人数",
          prop: "StudentCount"
        },
        // {
        //   label: "状态",
        //   prop: "Status_V"
        // }
      ],
      statusList: [
        { label: "草稿", value: 0 },
        { label: "已提交", value: 2 }
      ],
      majorList: []
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "success"
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    },
    statusTextFilter(status) {
      const statusMap = {
        0: "已保存",
        1: "已提交"
      };
      if (statusMap[status]) {
        return statusMap[status];
      }
    }
  },
  async created() {
    await this.initialize();
    // await this.getMajorList();
  },
  methods: {
    handleSearch() {
      this.initialize();
    },
    async initialize() {
      try {
        this.loading = true;
        // this.queryParams.SchoolId = this.SchoolId;
        await this.getTableData();
      } catch (err) {
        console.log("err:", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async handleSubmit(id) {
      this.$confirm(
        "此操作将会提交申请，数据将不能被修改，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(async () => {
          const res = await submit(id);
          if (res.IsSuccess) {
            this.$message.success("提交成功");
            this.initialize();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交"
          });
        });
    },
    async handleDelete(id) {
      this.$confirm("此操作将会永久删除此数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteLayout(id);
          if (res.IsSuccess) {
            this.$message.success("删除成功");
            this.initialize();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async getMajorList() {
      const res = await getMajorList();
      if (res.IsSuccess) {
        this.majorList = res.Result;
      }
    },
    handleCreate() {
      this.$router.push(
        `/school/practice/arrange/create?schoolId=${this.SchoolId}`
      );
    },
    handleEdit(id) {
      this.$router.push(
        `/school/practice/arrange/edit?id=${id}&schoolId=${this.SchoolId}`
      );
    },
    handleView(id) {
      this.$router.push(
        `/school/practice/arrange/view?id=${id}&schoolId=${this.SchoolId}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
</style>
